import React from "react"
import styled from "styled-components"
import { Text } from "@atoms"
import QuoteIconCosmic from "@assets/icons/quotation-graphic-cosmic.svg"

const QuoteBlockCosmic = ({ quoteData, ...props }) => {
  const { quote, author } = quoteData
  return (
    <CompWrapper {...props} data-comp={QuoteBlockCosmic.displayName}>
      <QuoteWrapper>
        <img src={QuoteIconCosmic} alt="Quotation Icon" />
        <TextWrapper>
          <Text
            style={{ fontSize: "28px", lineHeight: "34px", color: "#FFFFFF" }}
          >
            {quote?.quote && quote.quote}
          </Text>
          {author && (
            <AuthorDetailsFlex>
              {author?.fullName && (
                <Text
                  style={{
                    marginTop: "1px",
                    fontSize: "16px",
                    display: "inline",
                    color: "#FFFFFF",
                  }}
                >
                  <span style={{ fontWeight: 700 }}>{author?.fullName}</span>
                  <span style={{ fontWeight: 400 }}>
                    {author?.role && `, ${author?.role}`}
                    {author?.company?.name && `, ${author?.company?.name}`}
                  </span>
                </Text>
              )}
            </AuthorDetailsFlex>
          )}
        </TextWrapper>
      </QuoteWrapper>
    </CompWrapper>
  )
}

QuoteBlockCosmic.displayName = "QuoteBlockCosmic"
export default QuoteBlockCosmic

const CompWrapper = styled.div`
  background: #090069;
  width: 100%;
  padding: 50px 50px 50px 50px;
  img {
    width: 11%;
    height: auto;
    padding-right: 25px;
    position: relative;
    top: -75px;
  }
`
const QuoteWrapper = styled.div`
  display: flex;
`
const TextWrapper = styled.div`
  display: inline-block;
`

const AuthorDetailsFlex = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
`
